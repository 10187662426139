/* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
function openNav() {
    document.getElementById('page-wrapper').classList.add('mobile-menu-active');
}

/* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
function closeNav() {
    document.getElementById("page-wrapper").classList.remove('mobile-menu-active');
}

function goBack() {
    $(".sidenav-panel").removeClass("sidenav-panel_open");
}

$(document).ready(function () {

    var $menu = '<div class="sidenav">\n' +
        '          <div class="sidenav-header">Menu\n' +
        '            <div class="sidenav-control"><a class="closebtn" href="javascript:void(0)" onclick="closeNav()"><span>×</span></a></div>\n' +
        '          </div>\n' +
        '          <div class="sidenav-panels">\n' +
        '            <div class="sidenav-panel" id="panel-menu">\n' +
        '               <ul>' + $( "#nav-main" ).html() + '</ul>\n' +
        '               <ul>' + $( "#nav-top" ).html() + '</ul>\n' +
        '            </div>\n' +
        '          </div>\n' +
        '        </div>';

    $($menu).appendTo( "#page-wrapper" );

    //sub
    $("#panel-menu > ul > li.has-subitems").each(function(index,value){

        var itemName = $(this).find("> a").text();
        var subItems = $(value).find(".submenu").html();

        var panel = '<div id="panel-'+ index +'" class="sidenav-panel sidenav-panel-sub"><a href="javascript:void(0)" class="backbtn"><span class="custom-arrow"></span> Terug</a><div class="sidenav-panel-label">'+ itemName +'</div>'+ subItems +'</div>';
        $(".sidenav-panels").append(panel);

        $(this).find('.dropdown-toggle').click(function(e){
            $("#panel-"+index).addClass("sidenav-panel_open");
        });
        

        //subsub
        $(".sidenav-panel-sub").find("ul li.has-subitems").each(function(index1,value2){
            var subItemName = $(this).find("> a").text();
            var subsubItems = $(value2).find(".submenu").html();

            var panel2 = '<div id="panel-sub-'+ index1 +'" class="sidenav-panel sidenav-panel-sub-sub"><a href="javascript:void(0)" class="backbtn"><span class="custom-arrow"></span> Terug</a><div class="sidenav-panel-label">'+ subItemName +'</div>'+ subsubItems +'</div>';
            $(".sidenav-panels").append(panel2);

            $(value2).find('.dropdown-toggle').click(function(e){
                $("#panel-sub-"+index1).addClass("sidenav-panel_open");
            });
        });
    });

    $(".backbtn").click(function(){
        $(this).parents(".sidenav-panel").toggleClass("sidenav-panel_open");
    });
});