$(document).ready(function () {
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    convertToTap();
    handleStickyNavbar();
    floatingFormLabels();
    customFileUpload();
    customSelect();
    handleSelect();
    youtubeVideoComponent();

    $('body').addClass("loading");

    $(window).resize(function() {

    });

    $('#nav-main li:last-child').addClass('last-child');
});

$(window).on("load", function() {
    handleFooter();
    $(window).trigger('resize');
});

(function($) {
    /*
    * We need to turn it into a function.
    * To apply the changes both on document ready and when we resize the browser.
    */
    function mediaSize() {
        /* Set the matchMedia */
        if (window.matchMedia('(max-width: 768px)').matches) {
            /* Changes when we reach the max-width  */
            $(".list").hide();
            $(".contact-cta").appendTo("#mobile-list");
        } else {
            $(".list").show();
            $(".contact-cta").appendTo("#desktop-list");
        }
    }

    /* Call the function */
    mediaSize();
    /* Attach the function to the resize event listener */
    window.addEventListener('resize', mediaSize, false);

})(jQuery);

function matchHeight() {
    $('article .block-title').matchHeight();
}

function handleCarousel() {
    $('.owl-carousel').owlCarousel({
        navigation: false,
        dots: true,
        items:1,
        autoplay:true,
        loop:true
    })
}

function gallery() {

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {
    progressiveImageLoader();
    progressiveBackgroundImage();
};

function progressiveBackgroundImage() {

    var placeholder = $(".bg-progressive");

    placeholder.each(function(index,element) {

        var imgUrlSmall = $(element).data('small');
        var imgUrlLarge = $(element).data('large');

        var imageWrapperSmall = $('<div class="background-image-small"></div>');
        var imageWrapperLarge = $('<div class="background-image-large"></div>');

        $(imageWrapperSmall).css("background-image","url(" + imgUrlSmall +")");
        $(imageWrapperLarge).css("background-image","url(" + imgUrlLarge +")");

        $(imageWrapperSmall).appendTo(element);
        $(imageWrapperLarge).appendTo(element);

        var img = new Image();
        img.src = imgUrlLarge;

        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };
    });
}

function progressiveImageLoader() {
    var placeholder = $("figure.figure-progressive");

    placeholder.each(function(index,element) {

        //load small image
        var imageWrapperSmall = $('<img class="image-small">');
        imageWrapperSmall.attr('src', $(element).attr('data-small'));

        // //load large image
        var imageWrapperLarge = $('<img class="image-large">');
        imageWrapperLarge.attr('src', $(element).attr('data-large')).attr('srcset', $(element).attr('data-srcset')).attr('alt', $(element).attr('data-alt'));;

        imageWrapperSmall.appendTo(element);
        imageWrapperLarge.appendTo(element);

        var img = new Image();
        img.src = $(element).attr('data-large');

        //create image in DOM
        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };

    });
}

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

function convertToTap() {
    $(".tabs").each(function (index, element) {

        var tabsContainer = $(element).find(".tab-container");
        var tabsComponent = tabsContainer.find(".tab-component");
        var accordionElement = $('<div class="accordion" id="accordionComponent"></div>');

        $(accordionElement).appendTo(tabsContainer);

        var $newAccordionItemTitle = new Array();
        var $newAccordionItemText = new Array();
        //
        tabsComponent.find(".nav-tabs .nav-item").each(function (index, element) {
            $newAccordionItemTitle.push(element.text);
            $(accordionElement).append('<div class="card"><div class="card-header" id="heading'+ index +'" data-toggle="collapse" data-target="#collapse'+ index +'" aria-controls="collapse'+ index +'">' + $newAccordionItemTitle[index] +'<span class="custom-arrow"></span></div></div>');

        });

        tabsComponent.find(".tab-content .tab-pane").each(function (index, element) {
            $newAccordionItemText.push($(this).text());
            accordionElement.find('.card:nth-child(' + (index + 1) +')').append('<div class="collapse" id="collapse'+ index +'" data-parent="#accordionComponent"><div class="card-body">'+ $newAccordionItemText[index] +'</div></div>');
            accordionElement.find('.card:first-child .card-header').attr("aria-expanded","true");
            accordionElement.find('.card:first-child .collapse').addClass("show");
        });

        $(window).resize(function() {
            var screenSize = $( window ).outerWidth();

            if (screenSize < 768) {
                $(accordionElement).appendTo(tabsContainer);
                $(tabsComponent).detach();
            }
            else {
                $(tabsContainer).find(accordionElement).detach();
                $(tabsComponent).appendTo(tabsContainer);
            }
        });

    });
}

function handleStickyNavbar() {
    var navbar = $("#navbar");

    navbar.removeClass("sticky");

    $(window).scroll(function() {

        if(navbar !== undefined && navbar !== null){
            var offsetTopPosition = navbar.offsetTop;

            if (window.pageYOffset >= 1) {
                navbar.addClass("sticky")
            } else {
                navbar.removeClass("sticky");
            }
        }
    });

}

function floatingFormLabels() {
    $('.floating-label').each(function () {
        var inputField = $(this).find('input, textarea');

        if(inputField.val()){
            $(this).addClass('float');
        } else {
            $(this).removeClass('float');
        }

        $('input, textarea').on('change paste keyup focus', function (index, element) {
            if($(this).val() != ""){
                $(this).parent().addClass('float');
            } else {
                $(this).parent().removeClass('float');
            }
        });

    });
}

function customFileUpload() {
    $('input[type=file]').each(function (index, element) {
        var fileElement = $(element).parent();
        var fileNameDiv = '<div class="custom-upload-filename"></div>';
        $(fileNameDiv).appendTo(fileElement);

        $(element).on('change', function () {
            $(fileElement).find(".custom-upload-filename").empty();
            var filename = $(element).val().replace(/.*(\/|\\)/, '');
            fileElement.find(".custom-upload-filename").append(filename);
        });
    });
}

function youtubeVideoComponent() {
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


    $(".video-wrapper").each(function (index, element) {
        var videoWrapper = $(element);
        $(element).on('click', function (index, element) {
            var youtubeHash = $("#player").attr("data-youtube-hash");

            $(videoWrapper).toggleClass("pullout");
            $("body").toggleClass("fixed");

            if ( $('.video-wrapper').hasClass('pullout') ) {
                $('.video-close').removeClass('invisible');
                $('.video-close').addClass('visible');
            }

            if ( !$('.video-wrapper').hasClass('pullout') ) {
                $('.video-close').removeClass('visible');
                $('.video-close').addClass('invisible');
            }

            if($("#player").is("iframe")){
                $("#player").replaceWith("<div id='player' data-youtube-hash='"+ youtubeHash +"'></div>");
            } else {
                var player = new YT.Player('player', {
                    height: '390',
                    width: '640',
                    videoId: youtubeHash,
                    events: {
                        'onReady': onPlayerReady,
                        'onPlaying' : onPlaying,
                        'onclick' : onPlayerClick
                    }
                });
            }

        });

        function onPlayerClick(event){
            event.target.stopVideo();
        }

        function onPlayerReady(event) {
            event.target.playVideo();
        }

        function onPlaying(event) {
            event.target.stopVideo();
        }
    });
}

function customSelect() {
	var $selectElem = $("select.custom-select");
	var list = [];

	$('select.custom-select option').each(function(){
		list.push("<li role='option' aria-selected='false' data-option='" + $(this).val() + "'>" + $(this).text() + "</li>");
	});

	var customSelect = '<div class="md-select" id="'+$selectElem.attr('id')+'">\n' +
		'          <label for="'+$selectElem.attr('id')+'">\n' +
		'            <button type="button">'+ $selectElem.find('option:first-of-type').text() + '</button>\n' +
		'          </label>\n' +
		'          <ul role="listbox" id="'+$selectElem.attr('id')+'" name="'+$selectElem.attr('name')+'">' + list.join("") + '</ul>\n' +
		'        </div>';

	$(customSelect).insertBefore($selectElem);
}

function handleSelect() {
    $('.md-select').each(function (index, element) {
        $(element).click('click', function (e) {
            e.preventDefault();
            $(element).toggleClass('active')

        });
        $(element).find('ul li').on('click', function () {
            //default list
            if($(this).find('input').length === 0) {
                $(element).find('ul li').attr('aria-selected', false);
                $(this).attr('aria-selected', true);

                //multiselect list
            } else {
                if($(this).attr('aria-selected') === 'false'){
                    $(this).attr('aria-selected', true);
                } else {
                    $(this).attr('aria-selected', false);
                }
            }

            var v = $(this).text();

            $(element).find('ul li').not($(this)).removeClass('active');
            $(this).removeClass('active');
            $(element).find('label button').text(v);

            $(element).next().val(v);
            $(element).next().trigger('change');
        })
    });
}